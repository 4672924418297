import "./styles/global.css?__remix_sideEffect__";

import { I18nextProvider } from 'react-i18next';
import { cssBundleHref } from '@remix-run/css-bundle';
import { type LinksFunction } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { createInstance } from 'i18next';

import { AuthModalProvider } from './providers/auth-modal-provider';
import { FeatureFlagProvider } from './providers/feature-flag-provider';
import Body from './body';
import Head from './head';
import { type loader } from './root-loader';

export { loader } from './root-loader';
export { RootErrorBoundary as ErrorBoundary } from './root-error-boundary';

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


const i18n = createInstance();

function App() {
  const loaderData = useLoaderData<typeof loader>();
  const { featureToggleDefinitions, lng, resources } = loaderData;

  i18n.init({
    lng,
    fallbackLng: 'en-US',
    defaultNS: 'label',
    resources
  });

  return (
    <I18nextProvider i18n={i18n}>
			<html lang={lng}>
				<FeatureFlagProvider
          featureToggleDefinitions={featureToggleDefinitions}>

					<AuthModalProvider>
						<Head />
						<Body />
					</AuthModalProvider>
				</FeatureFlagProvider>
			</html>
		</I18nextProvider>);

}

export default withSentry(App);