import { Fragment } from 'react';
import personalisationStyles from '@cx-shared/account-personalisation/style.css';
import { Links, Meta, useLocation, useRouteLoaderData } from '@remix-run/react';

import { type Repub, REPUBS } from '@cx-modules/constants';

import ocTheme from '~/themes/OC-theme.css';
import usTheme from '~/themes/US-theme.css';
import type { FontAttribs } from '~/types';

import {
  featureFlags,
  useFeatureFlag } from
'./providers/feature-flag-provider';
import { GoogleTagManagerHead } from './scripts/tracking/GoogleTagManagerHead';
import { VwoAnalytics } from './scripts/tracking/VwoAnalytics';
import { ClientHintCheck } from './utils/client-hints';
import { useNonce } from './utils/nonce-provider';
import { type loader } from './root-loader';

export const Favicon = ({
  repub,
  resourcesUrl



}: {repub: Repub;resourcesUrl: string;}) => {
  const faviconPath = `${resourcesUrl}/assets/img/`;

  const favicon =
  repub === REPUBS.US.name ?
  `${faviconPath}us-favicon.png` :
  `${faviconPath}oc-favicon.svg`;

  return (
    <>
			<link
        as="image"
        href={favicon}
        key={`preload-${favicon}`}
        rel="preload" />

			<link href={favicon} key={`icon-${favicon}`} rel="icon" />
		</>);

};

export const Fonts = ({ url: urlString }: {url: string;}) => {
  const url = new URL(urlString);
  const baseFonts: FontAttribs[] = [
  // Public Sans
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;300;400;500;600;700&display=swap',
    as: 'font',
    crossOrigin: 'anonymous'
  },
  // Futura PT Condensed
  {
    rel: 'preload',
    href: 'https://use.typekit.net/af/3b8138/00000000000000000001203f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3',
    as: 'font',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    href: 'https://use.typekit.net/af/3b8138/00000000000000000001203f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3',
    as: 'font',
    type: 'font/woff',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    href: 'https://use.typekit.net/af/3b8138/00000000000000000001203f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3',
    as: 'font',
    type: 'font/opentype',
    crossOrigin: 'anonymous'
  }];


  const ocFonts: FontAttribs[] = [
  {
    rel: 'preload',
    href: 'https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700;900&display=swap',
    as: 'style'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700;900&display=swap'
  }];


  const fonts = [
  ...baseFonts,
  ...(url.pathname === '/subscribe' ? ocFonts : [])];


  return (
    <>
			{fonts.map((data) =>
      <link key={`${data.rel}-${data.href}`} {...data} />
      )}
		</>);

};

export default function Head() {
  const { pathname } = useLocation();
  const nonce = useNonce();
  const rootLoaderData = useRouteLoaderData<typeof loader>('root')!;
  const disableGtm = useFeatureFlag(featureFlags.DISABLE_GOOGLE_TAG_MANAGER);
  const disableVwo = useFeatureFlag(featureFlags.DISABLE_VWO);

  const {
    baseUrl,
    countryCode,
    gtmId,
    myocData,
    repub,
    resourcesUrl,
    state,
    url
  } = rootLoaderData;

  const repubStyles = repub === REPUBS.OC.name ? ocTheme : usTheme;
  const stylesToInjectAsLinks = [repubStyles, personalisationStyles];

  return (
    <head>
			<ClientHintCheck nonce={nonce} />
			<meta charSet="utf-8" />
			<meta content="width=device-width,initial-scale=1" name="viewport" />
			<meta
        content={`app-id=6459478704, app-argument=${pathname}`}
        name="apple-itunes-app" />

			<meta content="telephone=no" name="format-detection" />
			<base href={baseUrl} />
			<Meta />
			<Links />
			<Favicon repub={repub} resourcesUrl={resourcesUrl} />
			<Fonts url={url} />
			{stylesToInjectAsLinks.map((href) =>
      <Fragment key={href}>
					<link as="style" href={href} rel="preload" />
					<link href={href} rel="stylesheet" />
				</Fragment>
      )}
			<GoogleTagManagerHead
        country={countryCode}
        countryCode={countryCode}
        gtmId={gtmId}
        repub={repub}
        shouldRender={
        !disableGtm && (
        ENV.ENVIRONMENT === 'PROD' ||
        ENV.ENVIRONMENT === 'STG' ||
        ENV.ENVIRONMENT === 'TEST')
        }
        state={state}
        userGroup={myocData.userGroup}
        userId={myocData.myOCUser?.userID || ''} />

			<VwoAnalytics repub={repub} shouldRender={!disableVwo} />
		</head>);

}